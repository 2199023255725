import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { usePath } from '@vdx/hookrouter';
import MenuItem from './MenuItem';
import SubMenu from './SubMenu';

const ParentPopupState = React.createContext(null);

const Entry = styled(({ className, navLink, translationNamespace }) => {
  const currentPath = usePath();
  const hasActiveSubItem =
    navLink.items?.find(subItem =>
      subItem.exact
        ? subItem.href === currentPath
        : currentPath.startsWith(subItem.href)
    ) || false;
  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'sidebar_menu',
  });

  useEffect(() => {
    if (hasActiveSubItem) popupState.open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ParentPopupState.Provider value={popupState}>
      <MenuItem
        className={className}
        translationNamespace={translationNamespace}
        item={navLink}
        popupState={popupState}
      />
      {navLink.items && (
        <SubMenu
          menuItem={navLink}
          popupState={popupState}
          translationNamespace={translationNamespace}
        />
      )}
    </ParentPopupState.Provider>
  );
})`
  padding-left: ${({ theme: { typography, spacing } }) =>
    typography.pxToRem(spacing(4))};
`;

Entry.propTypes = {
  navLink: PropTypes.shape({}),
  translationNamespace: PropTypes.string,
};

export default Entry;
