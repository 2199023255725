import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Container, LinearProgress } from '@material-ui/core';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import defaultTheme from '@vdx-platform/core/vizidox-brand-helpers/theme/default';
import AppThemeProvider from '@vdx-platform/core/shared/theme';
import AppHelmet from '@vdx-platform/core/helmet';
import menuStore from '@vdx-platform/core/shared/app/menu/store';
import authStore from '@vdx-platform/core/auth/store';
import userStore, { ISSUER_TYPE } from '@vdx-platform/core/user/store';
import { get as getUserType } from '@vdx-platform/core/user/services/storage/user_type';
import useQueryParamIssuer from '@vdx-platform/core/user/utils/useQueryParamIssuer';
import Footer from '@vdx-platform/core/shared/Footer';
import Sidebar from './Navigation/Drawer';
import Header from './Header';
import GlobalStyles from './global/styles';

const Layout = styled.div`
  background-color: ${({ theme }) => theme.palette.background.default};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContent = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 4rem;
  margin-top: ${({ theme }) => theme.mixins.toolbar.height}px;
`;

const Content = styled(Container)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
`;

const LoadingPageProgress = styled(LinearProgress)`
  margin-top: ${({ theme }) => theme.mixins.toolbar.height}px;
`;
function AdministrativeLayout({ children }) {
  const type = userStore.useStoreState(state => state.type);

  const issuer = useQueryParamIssuer();
  const issuerTheme = issuer?.theme;

  const theme = (type === ISSUER_TYPE && issuerTheme) || defaultTheme;

  return (
    <AppThemeProvider theme={theme}>
      <AppHelmet />
      <GlobalStyles />
      <Layout>
        <Header />
        <Sidebar />
        <Suspense fallback={<LoadingPageProgress />}>
          <MainContent>
            <Content disableGutters maxWidth="xl">
              {children}
            </Content>
          </MainContent>
        </Suspense>
        <Footer />
      </Layout>
    </AppThemeProvider>
  );
}

AdministrativeLayout.propTypes = {
  children: PropTypes.node,
};

function AdministrativeThemeContainer({ children }) {
  useTranslation(['admin-layout']);

  const roles = authStore.useStoreState(state => state.profile.roles);
  const issuers = authStore.useStoreState(state => state.profile.issuers);

  const type = getUserType() || roles.find(r => r.role === ISSUER_TYPE)?.role;

  return (
    <userStore.Provider initialData={{ type, issuers }}>
      <menuStore.Provider>
        <AdministrativeLayout>{children}</AdministrativeLayout>
      </menuStore.Provider>
    </userStore.Provider>
  );
}

AdministrativeThemeContainer.propTypes = {
  children: PropTypes.node,
};

export default AdministrativeThemeContainer;
