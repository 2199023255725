import React from 'react';
import styled from 'styled-components';
import { Toolbar as MuiToolbar, AppBar, Container } from '@material-ui/core';
import useDeviceSize from '@vdx-platform/core/sizes/breakpoints/use_screen_size';
import BrandLogo from '@vdx-platform/core/shared/app/BrandLogo';
import MenuIconButton from '@vdx-platform/core/shared/app/menu/trigger';
import { A } from '@vdx/hookrouter';
import Profile from './profile';
import TopNavigation from '../Navigation/Top';

const Toolbar = styled(MuiToolbar)`
  padding-left: 0.1rem;
  padding-right: 0.1rem;
`;

const LogoWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-left: 1rem;
`;

const Actions = styled.div`
  display: flex;
  margin-left: auto;
`;

const Header = styled(({ className }) => {
  const { isSmall } = useDeviceSize();

  return (
    <AppBar className={className} color="primary">
      <Container maxWidth={false}>
        <Toolbar>
          <LogoWrapper>
            {isSmall && (
              <MenuIconButton
                css={`
                  color: ${({ theme }) => theme.palette.primary.contrastText};
                `}
              />
            )}
            <BrandLogo component={A} href="/dashboard" color="contrast" />
          </LogoWrapper>
          <Actions>
            <TopNavigation />
            <Profile />
          </Actions>
        </Toolbar>
      </Container>
    </AppBar>
  );
})``;

export default Header;
