import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { lighten, darken } from 'polished';
import { mapValues } from 'lodash';

const BOLD = 'bold';
const REGULAR = 400;
const CAPS = 'uppercase';
const TONAL_OFFSET = 0.1;

const backgroundTransformer = palette => ({
  default: palette.background.default,
  paper: palette.background.surface,
});

const textTransformer = palette => ({
  primary: palette.text.default,
  secondary: palette.text.default,
});

const variantsTransformer = palette =>
  mapValues(
    {
      ...palette.variants,
      default: palette.text.default,
    },
    variant => ({
      main: variant,
      light: lighten(TONAL_OFFSET, variant),
      dark: darken(TONAL_OFFSET, variant),
      contrastText: palette.text.contrast,
    })
  );

export default ({ palette, ...theme }) => {
  const colors = variantsTransformer(palette);
  return responsiveFontSizes(
    createMuiTheme({
      palette: {
        ...colors,
        text: textTransformer(palette),
        background: backgroundTransformer(palette),
        action: {
          hover: 'rgba(0,0,0,0.08)',
          hoverOpacity: 0.08,
        },
      },
      typography: {
        fontFamily: 'Khula, Raleway, Arial, sans-serif',
        htmlFontSize: 10,
        h1: {
          fontFamily: 'Arial',
          fontSize: '2.4rem',
          textTransform: CAPS,
          fontWeight: BOLD,
        },
        h2: {
          fontFamily: 'Arial',
          fontSize: '1.8rem',
          fontWeight: REGULAR,
        },
        h3: {
          fontFamily: 'Arial',
          fontSize: '1.8rem',
          textTransform: CAPS,
          fontWeight: REGULAR,
        },
        h4: {
          fontFamily: 'Arial',
          fontSize: '1.4rem',
          textTransform: CAPS,
          fontWeight: REGULAR,
        },
        h5: {
          fontFamily: 'Arial',
          fontSize: '1.4rem',
          textTransform: CAPS,
          fontWeight: REGULAR,
        },
        h6: {
          fontFamily: 'Arial',
          fontSize: '1.4rem',
          textTransform: CAPS,
          fontWeight: REGULAR,
        },
        body1: {
          fontSize: '1.4rem',
          fontWeight: REGULAR,
        },
        body2: {
          fontSize: '1.4rem',
          fontWeight: REGULAR,
        },
        button: {
          fontFamily: 'Arial',
        },
      },
      mixins: {
        toolbar: {
          height: 64,
        },
      },
      overrides: {
        MuiCssBaseline: {
          '@global': {
            html: {
              fontSize: '62.5%' /* 62.5% de 16px = 10px */,
            },
          },
        },
        MuiButton: {
          root: {
            borderRadius: '2.5rem',
            minWidth: 100,
          },
        },
        MuiInputBase: {
          root: {
            fontFamily: 'Raleway',
          },
        },
        MuiChip: {
          root: {
            fontFamily: 'Raleway',
          },
        },
        MuiButtonBase: {
          root: {
            fontFamily: 'Arial',
          },
        },
        MuiLinearProgress: {
          colorPrimary: {
            backgroundColor: colors.info.light,
          },
          barColorPrimary: {
            backgroundColor: colors.info.dark,
          },
          barColorSecondary: {
            backgroundColor: colors.info.dark,
          },
        },
      },
      ...theme,
      shadows: [
        'none',
        'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
        'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
        'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
        'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
        'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
        'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
        'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
        'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
        'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
        'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
        'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
        'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
        'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
        'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
        'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
        'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
        'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
        'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
        'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
        'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
        'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
        'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
        'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
        'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
      ],
    })
  );
};
