import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Container } from '@material-ui/core';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

const Footer = styled(({ className }) => (
  <Container component="footer" fullWidth className={className}>
    <Copyright />
  </Container>
))`
  margin-top: auto;
  opacity: 0.5;
  padding: ${({ theme: { typography, spacing } }) =>
    typography.pxToRem(spacing(2))};
  text-align: center;
`;

export const Copyright = styled(({ className }) => (
  <Typography className={className} variant="body1">
    © 2019-{new Date().getFullYear()} Vizidox Solutions Limited.{' '}
    <Trans i18nKey="ReservedRights" ns="admin-layout">
      All Rights Reserved.
    </Trans>
  </Typography>
))``;

Copyright.propTypes = {
  className: PropTypes.string,
};

export default Footer;
