import React from 'react';
import styled from 'styled-components';
import { lowerCase } from 'lodash';
import { lazy } from '@vdx/loadable-component';
import userStore from '@vdx-platform/core/user/store';
import Drawer from '@vdx-platform/core/shared/app/menu/Drawer';
import { fade } from '@material-ui/core/styles/colorManipulator';
import MenuItem from '@vdx-platform/core/shared/app/menu/Drawer/MenuItem';

const Navigation = lazy(props => import(`./${props.userType}/Drawer`));

const MenuContent = styled(({ className }) => {
  const userType = userStore.useStoreState(state => state.type);
  return (
    <Navigation
      key={lowerCase(userType)}
      userType={lowerCase(userType)}
      translationNamespace="admin-layout"
      className={className}
    />
  );
})`
  ${Drawer.Menu.Divider} {
    background-color: ${({ theme }) =>
      fade(theme.palette.primary.light, theme.palette.action.focusOpacity)};
  }
  ${Drawer.Menu.Entry} {
    background-color: ${({ theme }) => theme.palette.primary.contrastText};
    color: ${({ theme }) => theme.palette.primary.main};
    &:hover,
    &:focus {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
  ${Drawer.Menu} {
    & [class*='--active'] {
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.contrastText};
      ${MenuItem.Text} {
        font-weight: bold;
      }
    }
  }
  ${Drawer.Menu.Entry.SubMenu} {
    background-color: ${({ theme }) => theme.palette.primary.light};
    color: ${({ theme }) => theme.palette.primary.contrastText};
    & [class*='--active'] {
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.contrastText};
      ${MenuItem.Text} {
        font-weight: bold;
      }
    }
  }
`;

export default MenuContent;
