import React, { Suspense } from 'react';
import styled from 'styled-components';
import { capitalize } from 'lodash';
import { Avatar, CircularProgress, Typography } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Img } from 'react-image';

const getInitials = name => {
  const words = name.split(' ');
  const { length } = words;
  return words
    .filter((item, index) => index === 0 || index === length - 1)
    .map(value => capitalize(value).charAt(0))
    .join('');
};

const AvatarLoading = styled(({ className }) => {
  return <CircularProgress className={className} />;
})`
  ${({ size }) =>
    size
      ? `
    height: ${size} !important;
    width: ${size} !important;`
      : ''}
`;

export default styled(
  ({
    className,
    src,
    alt,
    name,
    size,
    useInitials = false,
    loading = false,
  }) => {
    return (
      <Avatar className={className}>
        <Suspense fallback={<AvatarLoading size={size} />}>
          {loading && <AvatarLoading size={size} />}
          {!useInitials && !loading && (
            <Img src={src} useSuspense alt={alt || name} />
          )}
          {useInitials && !loading && (
            <Typography>{getInitials(name)}</Typography>
          )}
        </Suspense>
      </Avatar>
    );
  }
)`
  background-color: ${({ theme, color }) =>
    color ? fade(color, 0.74) : theme.palette.common.white};
  & p {
    font-size: inherit;
    font-weight: bold;
    position: relative;
    top: 2px;
  }
  ${({ size }) =>
    size
      ? `
    height: ${size};
    width: ${size};`
      : ''}
  & img {
    height: inherit;
    object-fit: contain;
    width: inherit;
  }
`;
