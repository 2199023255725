import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { KeyboardArrowUp } from '@material-ui/icons';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { bindTrigger } from 'material-ui-popup-state/hooks';
import useDeviceSize from '@vdx-platform/core/sizes/breakpoints/use_screen_size';
import UserPicture from '@vdx-platform/core/shared/components/Avatar';
import defaultAvatar from '@vdx-portal/assets/default-avatar.png';
import ResponsiveButton from '@vdx-platform/core/shared/components/ResponsiveButton';
import { usePopupState } from '../context/popup';

const ArrowVariants = {
  open: { rotate: '180deg' },
  close: { rotate: '0deg' },
};

const useStyles = makeStyles(() => ({
  picture: {
    marginRight: '1rem',
  },
}));

const TriggerArrow = forwardRef(({ isOpen, ...props }, ref) => {
  return (
    <motion.div
      initial={isOpen ? 'open' : 'close'}
      animate={isOpen ? 'close' : 'open'}
      variants={ArrowVariants}
      transition={{ type: 'spring' }}
    >
      <KeyboardArrowUp ref={ref} {...props} />
    </motion.div>
  );
});
TriggerArrow.propTypes = {
  isOpen: PropTypes.bool,
};

const Profile = styled(({ className, name, photo, loadingPhoto }) => {
  const popupState = usePopupState();
  const classes = useStyles();

  const { isXSmall } = useDeviceSize();

  return (
    <ResponsiveButton
      className={className}
      {...bindTrigger(popupState)}
      variant="text"
      color="default"
      disableRipple
    >
      <UserPicture
        src={photo || defaultAvatar}
        className={classes.picture}
        loading={loadingPhoto}
      />
      {!isXSmall && (
        <Typography
          css={`
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: none;
            white-space: nowrap;
            color: ${({ theme }) => theme.palette.primary.contrastText};
          `}
          variant="h4"
          component="span"
        >
          {name}
        </Typography>
      )}
      <TriggerArrow
        css={`
          display: flex;
          color: ${({ theme }) => theme.palette.primary.contrastText};
        `}
        isOpen={popupState.isOpen}
      />
    </ResponsiveButton>
  );
})`
  ${p => p.theme.mixins.toolbar}

  padding-left: 1rem;
  padding-right: 1rem;
`;

export default Profile;
