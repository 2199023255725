/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ListItem, ListItemText, Grid } from '@material-ui/core';
import { usePath } from '@vdx/hookrouter';
import { Trans } from 'react-i18next';
import { bindToggle } from 'material-ui-popup-state/hooks';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import NavLink from '@vdx-platform/core/shared/hookrouter/NavLink';
import clsx from 'clsx';
import store from '../store';

export const ItemText = styled.span``;

const MenuItem = styled(
  ({ className, translationNamespace, item, popupState }) => {
    const currentPath = usePath();
    const toggle = store.useStoreActions(actions => actions.toggle);
    let isActive;

    const {
      items: itemChildren,
      Component: itemComponent,
      ...itemProps
    } = item;
    let props;
    if (itemChildren) {
      const hasActiveSubItem =
        itemChildren.find(subItem =>
          subItem.exact
            ? subItem.href === currentPath
            : currentPath.startsWith(subItem.href)
        ) || false;
      isActive = !!hasActiveSubItem;
      props = bindToggle(popupState);
    } else {
      props = {
        ...itemProps,
        component: itemComponent || NavLink,
        onClick: toggle,
      };
    }

    const finalClassName = clsx(className, isActive && `${className}--active`);

    return (
      <ListItem className={finalClassName} button {...props}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <ListItemText
              primary={
                <Trans i18nKey={item.key} ns={translationNamespace}>
                  {item.key}
                </Trans>
              }
              primaryTypographyProps={{
                variant: 'h3',
                component: ItemText,
                className: 'menu-item-text',
              }}
            />
          </Grid>
          {itemChildren && (
            <Grid item>
              {popupState.isOpen ? <ExpandLess /> : <ExpandMore />}
            </Grid>
          )}
        </Grid>
      </ListItem>
    );
  }
)``;

MenuItem.propTypes = {
  className: PropTypes.string,
  translationNamespace: PropTypes.string,
  item: PropTypes.shape({
    key: PropTypes.string,
    href: PropTypes.string,
    items: PropTypes.array,
  }),
  popupState: PropTypes.shape({
    isOpen: PropTypes.bool,
  }),
};

MenuItem.Text = ItemText;

export default MenuItem;
