import React from 'react';
import { Button } from '@material-ui/core';
import { useBreakpointMaxSize } from '@vdx-platform/core/sizes/use_breakpoint';

// eslint-disable-next-line react/prop-types
const ResponsiveButton = ({ children, ...props }) => {
  const isSmall = useBreakpointMaxSize('sm');
  return (
    <Button size={isSmall ? 'small' : 'medium'} {...props}>
      {children}
    </Button>
  );
};

export default ResponsiveButton;
