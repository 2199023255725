import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Collapse, List } from '@material-ui/core';
import { bindMenu } from 'material-ui-popup-state/hooks';
import MenuItem from './MenuItem';

const SubMenu = styled(
  ({ className, menuItem, popupState, translationNamespace }) => (
    <Collapse
      in={popupState.isOpen}
      timeout="auto"
      unmountOnExit
      {...bindMenu(popupState)}
    >
      <List className={className} component="nav">
        {menuItem.items.map(item => (
          <MenuItem
            translationNamespace={translationNamespace}
            popupState={popupState}
            key={item.key}
            item={item}
          />
        ))}
      </List>
    </Collapse>
  )
)`
  flex: 1;
  padding-bottom: unset;
  padding-top: unset;

  ${MenuItem} {
    padding-left: ${({ theme: { typography, spacing } }) =>
      typography.pxToRem(spacing(7))};
  }
`;

SubMenu.propTypes = {
  menuItem: PropTypes.shape({
    children: PropTypes.array,
  }),
  popupState: PropTypes.shape({
    isOpen: PropTypes.bool,
  }),
  translationNamespace: PropTypes.string,
};

export default SubMenu;
