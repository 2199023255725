import { action, createContextStore, actionOn } from 'easy-peasy';
import {
  get as getStoredUserType,
  set as setStoredUserType,
} from '../services/storage/user_type';
import {
  get as getStoredIssuer,
  set as setStoredIssuer,
} from '../services/storage/issuer';

export const ISSUER_TYPE = 'ISSUER';
export const USER_TYPE = 'USER';

const DEFAULT_TYPE = getStoredUserType() || USER_TYPE;

const setActiveIssuer = (issuers, type) => {
  const storedIssuer = getStoredIssuer() || {};
  const issuer = issuers.find(item => item.issuer.id === storedIssuer.id);
  if (issuer)
    return {
      issuer: issuer.issuer,
      isAdmin: type === USER_TYPE ? false : issuer.is_admin,
    };
  return {
    issuer: issuers && issuers.length > 0 ? issuers[0].issuer : null,
    isAdmin: type === USER_TYPE ? false : issuers[0].is_admin,
  };
};

export const userModel = ({ type = DEFAULT_TYPE, issuers }) => ({
  ...setActiveIssuer(issuers, type),
  type,

  setType: action((state, role) => {
    state.type = role;
    setStoredUserType(role);
  }),
  setIssuer: action((state, issuer) => {
    const selectedIssuer = issuers.find(item => item.issuer.id === issuer.id);
    state.issuer = selectedIssuer.issuer;
    state.isAdmin = selectedIssuer.is_admin;
    setStoredIssuer(selectedIssuer.issuer);
  }),
  onSetType: actionOn(
    actions => actions.setType,
    (state, { payload }) => {
      if (payload === USER_TYPE) {
        state.isAdmin = false;
      } else {
        state.isAdmin = issuers.find(
          item => item.issuer.id === state.issuer.id
        ).is_admin;
      }
    }
  ),
});

export default createContextStore(userModel, { name: 'UserType' });
