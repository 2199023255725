import React from 'react';
import { lowerCase } from 'lodash';
import styled from 'styled-components';
import { fade } from '@material-ui/core/styles/colorManipulator';
import MenuItem from '@vdx-platform/core/shared/app/menu/Header/MenuItem';
import { lazy } from '@vdx/loadable-component';
import userStore from '@vdx-platform/core/user/store';

const Navigation = lazy(props => import(`./${props.userType}/Top`));

const MenuContent = styled(({ className }) => {
  const userType = userStore.useStoreState(state => state.type);
  return (
    <div className={className}>
      <Navigation
        key={lowerCase(userType)}
        userType={lowerCase(userType)}
        translationNamespace="admin-layout"
      />
    </div>
  );
})`
  display: flex;
  & > a,
  & > div {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    overflow: hidden;
    position: relative;

    ${MenuItem.Text} {
      font-weight: normal;
    }

    &:after {
      background-color: ${({ theme }) => theme.palette.primary.contrastText};
      bottom: 0;
      content: '';
      height: 3px;
      left: 0;
      position: absolute;
      right: 0;
      transform: scale(0, 1);
      transition-duration: 0.5s;
      transition-timing-function: ease-in-out;
      width: 100%;
    }

    &:hover:after {
      transform: scale(1, 1);
    }
  }

  & > [class*='--active'] {
    background-color: ${({ theme }) =>
      fade(theme.palette.common.black, theme.palette.action.selectedOpacity)};
    color: ${({ theme }) => theme.palette.primary.contrastText};
    overflow: hidden;
    position: relative;

    ${MenuItem.Text} {
      font-weight: bold;
    }

    &:after {
      background-color: ${({ theme }) => theme.palette.primary.contrastText};
      bottom: 0;
      content: '';
      height: 3px;
      left: 0;
      position: absolute;
      transform: scale(1, 1);
      width: 100%;
    }
  }
`;

export default MenuContent;
