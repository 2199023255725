import React, { Suspense } from 'react';
import { CircularProgress, Link } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Img as ReactImage } from 'react-image';
import styled from 'styled-components';

export const Img = styled(ReactImage)`
  ${p => p.theme.mixins.toolbar}
  object-fit: contain;
  padding: 1.5rem;
  text-align: center;
  width: auto;
`;

const BrandLogo = styled(
  ({ className, color, component = Link, ...AnchorProps }) => {
    const { brand } = useTheme();

    const Anchor = component;

    return (
      <Anchor className={className} {...AnchorProps}>
        <Suspense fallback={<CircularProgress />}>
          <Img
            src={brand.logo[color]}
            decode={false}
            alt={brand.name}
            useSuspense
          />
        </Suspense>
      </Anchor>
    );
  }
)`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  text-decoration: none;
`;

BrandLogo.Img = Img;
export default BrandLogo;
