/* eslint-disable no-param-reassign */
import {
  createContextStore,
  action,
  thunk,
  thunkOn,
  actionOn,
} from 'easy-peasy';
import cancellable from '@vdx-platform/core/shared/store/cancellable_thunk';
import fetchThunkHelper from '@vdx-platform/core/shared/store/fetch_thunk_helper';
import { remove as removeUserType } from '@vdx-platform/core/user/services/storage/user_type';
import { remove as removeActiveIssuer } from '@vdx-platform/core/user/services/storage/issuer';
import keycloak from '@vdx-platform/core/auth/keycloak';
import pictureService from '@vdx-platform/core/user/services/get_picture';
import profileService from '@vdx-platform/core/user/services/get_profile';

const profileInitialState = {
  profile: {
    email: undefined,
    name: undefined,
    roles: {},
    issuers: [],
    // eslint-disable-next-line babel/camelcase
    public_picture: undefined,
    photo: null,
    loadingPhoto: true,
  },
  loading: false,
  success: false,
  error: undefined,
};

export const profileModel = (initialState = profileInitialState) => ({
  ...initialState,
  ...fetchThunkHelper('profile', () => cancellable(profileService)()),
  getPhoto: thunk(() => cancellable(pictureService)()),
  onGetProfileSuccess: actionOn(
    actions => actions.getProfile.successType,
    (state, { result }) => {
      state.loading = false;
      state.success = true;
      state.profile = {
        ...result,
        loadingPhoto: !!result.photo_id || false,
      };
    }
  ),
  onAfterGetProfile: thunkOn(
    actions => actions.getProfile.successType,
    (actions, payload, { getState }) => {
      if (getState().profile.photo_id) {
        actions.getPhoto();
      }
    }
  ),
  onGetPhotoSuccess: actionOn(
    actions => actions.getPhoto.successType,
    (state, { result }) => {
      state.profile.photo = URL.createObjectURL(result);
      state.profile.loadingPhoto = false;
    }
  ),
  onGetPhotoError: actionOn(
    actions => actions.getPhoto.failType,
    state => {
      state.profile.loadingPhoto = false;
    }
  ),
  setProfile: action((state, profile = {}) => {
    state.profile = {
      ...state.profile,
      ...profile,
    };
  }),
});

const authModel = {
  login: thunk((actions, options) => {
    keycloak.login(options);
  }),
  logout: thunk(() => {
    keycloak.logout();
  }),
  onLogout: thunkOn(
    actions => actions.logout.startType,
    () => {
      removeUserType();
      removeActiveIssuer();
    }
  ),
};

export default createContextStore(
  initialState => ({ ...profileModel(initialState), ...authModel }),
  { name: 'Auth' }
);
