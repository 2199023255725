import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  Divider as MUIDivider,
  Drawer as MUIDrawer,
} from '@material-ui/core';
import styled from 'styled-components';
import store from '../store';
import Entry from './Entry';
import SubMenu from './SubMenu';

const useStyles = makeStyles(theme => ({
  drawer: {
    top: `${theme.mixins.toolbar.height}px !important`,
    zIndex: `${theme.zIndex.appBar - 1} !important`,
  },
  anchorTop: {
    top: theme.mixins.toolbar.height,
  },
  backdrop: {
    top: theme.mixins.toolbar.height,
  },
}));

const Divider = styled(MUIDivider)``;

const Menu = styled(({ className, navLinks, translationNamespace }) => (
  <List className={className} component="nav">
    {navLinks.map((navLink, i) => (
      <Fragment key={navLink.key}>
        {i !== 0 && <Divider />}
        <Entry navLink={navLink} translationNamespace={translationNamespace} />
      </Fragment>
    ))}
  </List>
))`
  flex: 1;
  padding-bottom: unset;
  padding-top: unset;
`;

const Drawer = styled(({ className, navLinks, translationNamespace }) => {
  const isOpen = store.useStoreState(state => state.isOpen);
  const classes = useStyles();

  return (
    <MUIDrawer
      anchor="top"
      classes={{ root: classes.drawer, paperAnchorTop: classes.anchorTop }}
      className={className}
      open={isOpen}
      ModalProps={{
        BackdropProps: {
          classes: { root: classes.backdrop },
        },
      }}
    >
      <Menu navLinks={navLinks} translationNamespace={translationNamespace} />
    </MUIDrawer>
  );
})``;

Drawer.Menu = Menu;
Drawer.Menu.Entry = Entry;
Drawer.Menu.Entry.SubMenu = SubMenu;
Drawer.Menu.Divider = Divider;

Drawer.propTypes = {
  navLinks: PropTypes.arrayOf(PropTypes.shape({})),
  translationNamespace: PropTypes.string,
};

export default Drawer;
