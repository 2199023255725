import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

export const useBreakpointInterval = (start, end) => {
  const theme = useTheme();

  return useMediaQuery(theme.breakpoints.between(start, end));
};

export const useBreakpointMaxSize = key => {
  const theme = useTheme();

  return useMediaQuery(theme.breakpoints.down(key));
};

export const useBreakpointMinSize = key => {
  const theme = useTheme();

  return useMediaQuery(theme.breakpoints.up(key));
};
