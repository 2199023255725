import React, { Fragment, useMemo, useCallback } from 'react';
import { Menu, Divider, MenuItem, SvgIcon } from '@material-ui/core';
import {
  PowerOff as PowerOffIcon,
  Person as PersonIcon,
  AccountCircle as AccountCircleIcon,
  ChevronLeft as ChevronLeftIcon,
} from '@material-ui/icons';
import Icon from '@mdi/react';
import { mdiAccountTie as UserAdminIcon } from '@mdi/js';
import PropTypes from 'prop-types';
import { navigate } from '@vdx/hookrouter';
import { bindMenu } from 'material-ui-popup-state/hooks';
import { Trans } from 'react-i18next';
import authStore from '@vdx-platform/core/auth/store';
import userStore, {
  ISSUER_TYPE,
  USER_TYPE,
} from '@vdx-platform/core/user/store';
import OptionItem from './OptionItem';
import { usePopupState } from '../context/popup';
import SubOptions from './SubOptions';

const Options = () => {
  const popupState = usePopupState();

  const { roles } = authStore.useStoreState(state => state.profile);
  const hasIssuerRole = roles.some(entry => entry.role === ISSUER_TYPE);

  const setType = userStore.useStoreActions(actions => actions.setType);
  const currentType = userStore.useStoreState(state => state.type);

  const logout = authStore.useStoreActions(actions => actions.logout);

  const setIssuerRole = useCallback(() => {
    setType(ISSUER_TYPE);
    navigate('/dashboard');
  }, [setType]);

  const setUserRole = useCallback(() => {
    setType(USER_TYPE);
    navigate('/dashboard');
  }, [setType]);

  const items = useMemo(
    () => [
      {
        key: 'settings',
        onClick: popupState.close,
        AnchorProps: {
          href: '/profile',
        },
        label: (
          <Trans i18nKey="Profile" ns="admin-layout">
            User profile
          </Trans>
        ),
        icon: <AccountCircleIcon />,
      },
      ...(hasIssuerRole
        ? [
            {
              key: 'view',
              label: 'View Mode',
              icon: <ChevronLeftIcon />,
              AnchorProps: {
                role: 'button',
                tabindex: '0',
              },
              options: [
                {
                  key: 'issuer',
                  onClick: popupState.close,
                  label: 'Issuer',
                  Anchor: MenuItem,
                  AnchorProps: {
                    onClick: setIssuerRole,
                    disabled: currentType === ISSUER_TYPE,
                  },
                  icon: (
                    <SvgIcon>
                      <Icon path={UserAdminIcon} />
                    </SvgIcon>
                  ),
                },
                {
                  key: 'user',
                  onClick: popupState.close,
                  label: 'User',
                  Anchor: MenuItem,
                  AnchorProps: {
                    onClick: setUserRole,
                    disabled: currentType === USER_TYPE,
                  },
                  icon: <PersonIcon />,
                },
              ],
            },
          ]
        : []),
      {
        key: 'logout',
        onClick: popupState.close,
        AnchorProps: {
          role: 'button',
          tabindex: '0',
          onClick: logout,
        },
        label: (
          <Trans i18nKey="Logout" ns="admin-layout">
            Logout
          </Trans>
        ),
        icon: <PowerOffIcon />,
      },
    ],
    [
      popupState.close,
      hasIssuerRole,
      setIssuerRole,
      currentType,
      setUserRole,
      logout,
    ]
  );

  return (
    <Menu
      css={`
        [class^='MuiList-root'] {
          padding: 0;
        }
      `}
      {...bindMenu(popupState)}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      {items.map(({ key, ...item }, i) => (
        <Fragment key={key}>
          {!item.options && <OptionItem {...item} />}
          {item.options && <SubOptions {...item}>{item.options}</SubOptions>}
          {i < items.length - 1 && <Divider />}
        </Fragment>
      ))}
    </Menu>
  );
};

Options.propTypes = {
  hasIssuerRole: PropTypes.bool,
};

export default Options;
