/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { usePath } from '@vdx/hookrouter';
import styled from 'styled-components';
import { ListItem as MUIListItem, ListItemText } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { bindHover } from 'material-ui-popup-state/hooks';
import clsx from 'clsx';
import NavLink from '@vdx-platform/core/shared/hookrouter/NavLink';

export const ItemText = styled.span``;
export const ListItem = styled(MUIListItem)``;

const MenuItem = forwardRef(
  ({ className, translationNamespace, item, popupState, onClose }, ref) => {
    const currentPath = usePath();
    const {
      items: itemChildren,
      Component: itemComponent,
      ...itemProps
    } = item;
    let props;
    let isActive;

    if (itemChildren) {
      const hasActiveSubItem =
        itemChildren.find(subItem =>
          subItem.exact
            ? subItem.href === currentPath
            : currentPath.startsWith(subItem.href)
        ) || false;
      isActive = !!hasActiveSubItem;
      props = bindHover(popupState);
    } else {
      props = {
        component: itemComponent || NavLink,
        ...itemProps,
        onClick: onClose,
      };
    }

    const finalClassName = clsx(className, isActive && `${className}--active`);

    return (
      <ListItem className={finalClassName} button {...props} ref={ref}>
        <ListItemText
          primary={
            <Trans i18nKey={item.key} ns={translationNamespace}>
              {item.key}
            </Trans>
          }
          primaryTypographyProps={{
            variant: 'h4',
            component: ItemText,
          }}
        />
      </ListItem>
    );
  }
);

MenuItem.propTypes = {
  className: PropTypes.string,
  translationNamespace: PropTypes.string,
  item: PropTypes.shape({
    key: PropTypes.string,
    children: PropTypes.array,
  }),
  popupState: PropTypes.shape({
    close: PropTypes.func,
  }),
  onClose: PropTypes.func,
};

MenuItem.Text = ItemText;

export default MenuItem;
