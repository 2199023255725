/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react';
import { usePath, A, setLinkProps } from '@vdx/hookrouter';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const NavLink = forwardRef(({ exact, children, ...props }, ref) => {
  const currentPath = usePath();
  const linkProps = setLinkProps(props);
  const isActive = exact
    ? linkProps.href === currentPath
    : currentPath.startsWith(linkProps.href);

  const { className, activeClassName } = props;
  const finalClassName = clsx(
    className,
    isActive && (activeClassName || `${className}--active`)
  );

  return (
    <A {...linkProps} className={finalClassName} ref={ref}>
      {children}
    </A>
  );
});

NavLink.propTypes = {
  exact: PropTypes.bool,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  children: PropTypes.node,
};

export default NavLink;
